import React from "react"
import { MarkGithubIcon } from "@primer/octicons-react"

import SEO from "components/seo"
import ClientsCarousel from "components/clients"
import GitHubGeneral from "images/GitHub_General_Comment.png"
import GitHubBug from "images/GitHub_Bug_Risk.png"
import GitHubComplexity from "images/GitHub_Complexity.png"
import GitHubVideo from "images/GitHub_Code_Review.mp4"
import FAQSection from "components/faq-section"
import AnchorButton from "components/anchor-button"
import gitlab from "images/gitlab-logo.svg"

const CTASection = () => (
  <div className="flex flex-col pt-7 gap-6">
    <AnchorButton
      href={process.env.SOURCERY_DASHBOARD_URL + "/login?connection=github"}
      icon={
        <div style={{ padding: "11px" }}>
          <MarkGithubIcon size={24} />
        </div>
      }
    >
      Get started with GitHub
    </AnchorButton>
    <AnchorButton
      href={process.env.SOURCERY_DASHBOARD_URL + "/login?connection=gitlab"}
      icon={<img src={gitlab} width="48px" />}
    >
      Get started with GitLab
    </AnchorButton>
  </div>
)

const FinalCTASection = () => (
  <div className="text-white text-center py-8 items-center">
    <h2 className="text-5xl font-bold mb-4">Try Sourcery Today</h2>
    <p className="mb-6">See what faster code reviews can do for you.</p>
    <div className="flex flex-col mx-auto pt-7 pb-8 items-center">
      <CTASection />
    </div>
  </div>
)

const IndexPage = () => (
  <div>
    <SEO
      title="Sourcery | Instant Code Review for Faster Velocity"
      description="Sourcery reviews all of the changes to your code and gives you human-like reviews in seconds"
      keywords={[
        `sourcery`,
        `code review`,
        `developer tools`,
        `python`,
        "javascript",
        "typescript",
        `ai`,
      ]}
    />

    <div className="bg-gradient-to-b from-black to-purple-extradark overflow-hidden pt-[88px] md:pt-[96px]">
      <div>
        <div className="max-w-[347px] md:max-w-[928px] xl:max-w-[1000px] mx-auto pt-[88px] md:pt-[48px] pb-[32px] md:pb-[40px] xl:pb-[74px]">
          <div className="w-full flex font-body flex-col mx-auto px-[10px] md:px-0 sm:gap-4 text-[2rem]/[2.5rem] md:text-[3.5rem]/[4.04881rem] xl:text-7xl/[5.5rem] text-center text-black-100 font-medium -tracking-[0.06rem] md:-tracking-[0.105rem] xl:-tracking-[0.135rem]">
            <h1>Code reviews shouldn&apos;t slow you down</h1>
            <div className="flex flex-col md:flex-row justify-between items-center md:items-start mx-auto px-[10px] md:px-0">
              <p className="text-base font-body md:text-base/[1.38031rem] xl:text-[1.25rem]/[1.875rem] -tracking-[0.03rem] xl:-tracking-[0.0375rem] text-center text-black-200">
                Sourcery is your AI code reviewer who lives in your GitHub repos
                - instantly reviewing every pull request.
              </p>
            </div>
            <CTASection />
          </div>
        </div>
      </div>

      <div className="overflow-hidden pt-[26px] md:pt-[66.51px]">
        <div className="py-[12px] px-[10px] md:px-0 md:pt-[20.39px] md:pb-7">
          <ClientsCarousel />
        </div>
        <div className="text-center mx-auto max-w-4xl pt-8 mb-9 pb-6">
          <video
            src={GitHubVideo}
            autoPlay={true}
            muted={true}
            loop={true}
            playsInline={true}
            alt="Feature Highlight"
            className="rounded-xl shadow-3xl"
          />
        </div>
        <div className="text-center mx-auto max-w-4xl pt-8 mb-9 pb-6">
          <h2 className="text-5xl font-bold text-white mb-4">
            Code reviews. Always at your fingertips
          </h2>
          <p className="text-base font-body md:text-base/[1.38031rem] xl:text-[1.25rem]/[1.875rem] -tracking-[0.03rem] xl:-tracking-[0.0375rem] text-center text-black-200 pt-2">
            Sourcery is always working to review your teams code, so you can
            focus on heads down development
          </p>
        </div>
        <div>
          <div className="mx-auto max-w-7xl pt-6 pb-6">
            {/* Feature 1 */}
            <div className="flex flex-col md:flex-row items-center justify-between mb-8 rounded-lg p-6">
              <div className="md:w-1/2 w-full text-left pr-6 mb-6">
                <h3 className="text-3xl font-bold text-white mb-4">
                  Reviewing code the way you expect
                </h3>
                <p className="text-black-200 pb-8">
                  Sourcery reviews your code just like you&apos;d expect a
                  colleague to review it. Every PR gets a review from Sourcery
                  with general feedback, in-line comments, and relevant
                  suggestions.
                </p>
              </div>
              <div className="md:w-1/2 w-full">
                <img
                  src={GitHubGeneral}
                  alt="Feature Highlight"
                  className="rounded-xl shadow-xl max-w-full h-auto align-middle"
                />
              </div>
            </div>

            {/* Feature 2 */}
            <div className="flex flex-col md:flex-row items-center justify-between mb-8 rounded-lg p-6">
              <div className="md:w-1/2 w-full text-left pr-6 mb-6">
                <h3 className="text-3xl font-bold text-white mb-4">
                  Hunting for bug and security risks
                </h3>
                <p className="text-black-200 pb-8">
                  Sourcery is on the lookout for any dangerous things that might
                  have crept into your code. We&apos;re here to help catch
                  issues earlier.
                </p>
              </div>
              <div className="md:w-1/2 w-full">
                <img
                  src={GitHubBug}
                  alt="Feature Highlight"
                  className="rounded-xl shadow-xl max-w-full h-auto align-middle"
                />
              </div>
            </div>

            {/* Feature 3 */}
            <div className="flex flex-col md:flex-row items-center justify-between mb-8 rounded-lg p-6">
              <div className="md:w-1/2 w-full text-left pr-6 mb-6">
                <h3 className="text-3xl font-bold text-white mb-4">
                  Keeping things from getting too complicated
                </h3>
                <p className="text-black-200 pb-8">
                  Sometimes we need to write complex code to solve complex
                  problems. But sometimes we don&apos;t. Sourcery checks every
                  PR to make sure it&apos;s well designed and flags and fixes
                  dangerous complexity.
                </p>
              </div>
              <div className="md:w-1/2 w-full">
                <img
                  src={GitHubComplexity}
                  alt="Feature Highlight"
                  className="rounded-xl shadow-xl max-w-full h-auto align-middle"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Pricing and Testimonials Section */}
        {/*
        <div className="flex flex-col pt-[75px] md:pt-[50px] xl:pt-[70px] items-center">
          <div className="flex flex-col items-center">
            <div className="rotate-90 text-blue-200 mb-[8px]">
              <TagIcon size={24} />
            </div>
            <p className="font-mono font-bold text-base text-black-200 mb-[4px]">
              match typeOfUser:
            </p>
            <p className="md:text-[1.625rem]/[2.5rem] xl:text-[2rem]/[2.5rem] mb-[8px] -tracking-[0.06rem] font-bold text-[color:#F2F2F2]">
              Simple, flexible pricing
            </p>
            <p
              className="md:text-[0.875rem]/[1.5rem] xl:text-base text-blue-200 font-medium md:-tracking-[0.021rem]
            xl:-tracking-[0.024rem]"
            >
              Save up to <strong>20%</strong> with yearly subscription
            </p>
          </div>
          <ReviewPricing />
        </div>
        */}
        <div>
          <FAQSection />
        </div>
        <div>
          <FinalCTASection />
        </div>
      </div>
    </div>
  </div>
)

export default IndexPage
