import React, { useState } from "react"
import PropTypes from "prop-types"
import { ChevronDownIcon, ChevronRightIcon } from "@primer/octicons-react"

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="mb-6 border border-gray-600 rounded-lg overflow-hidden mx-auto max-w-xl shadow-lg">
      <button
        className="text-left w-full text-xl text-white font-semibold py-3 px-6 flex justify-between items-center bg-black hover:bg-gray-800 transition-colors duration-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        {isOpen ? (
          <ChevronDownIcon size={24} className="text-gray-300" />
        ) : (
          <ChevronRightIcon size={24} className="text-gray-300" />
        )}
      </button>
      {isOpen && (
        <div className="bg-gray-800 p-4 animate-slide-in">
          <p className="text-white text-opacity-90 text-left">{answer}</p>
        </div>
      )}
    </div>
  )
}

const FAQSection = () => {
  const faqs = [
    {
      question: "Can I use Sourcery on open source projects?",
      answer: "Sourcery is always free to use on public repos.",
    },
    {
      question: "Is Sourcery only for GitHub?",
      answer:
        "Right now we only have a GitHub integration. In the next few months we're looking to add GitLab and Bitbucket support. If you're looking for other platforms please let us know!",
    },
    {
      question: "What LLMs do you use for your reviews?",
      answer:
        "We primarily use GPT4-Turbo. In the future we are looking to add support for models that can be fine tuned and hosted by Sourcery or by you.",
    },
    {
      question: "How do you make sure my code is kept private?",
      answer:
        "We only send the sections of code that are necessary for our review analysis to a LLM and neither we nor the LLM providers we work with will retain any of your code beyond 30 days or use it to train any models.",
    },
  ]

  return (
    <div className="text-center p-8">
      <h2 className="text-3xl text-white font-bold mb-4">
        Frequently Asked Questions
      </h2>
      <p className="text-white mt-4 mb-8">
        More Questions? Feel free to{" "}
        <a
          href="mailto:hello@sourcery.ai"
          className="text-blue-300 underline hover:text-blue-200"
        >
          email us
        </a>
      </p>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  )
}

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}

export default FAQSection
